@function headings($from:1, $to:6) {
    @if $from == $to {
        @return 'h#{$from}';
    } @else {
        @return 'h#{$from},' + headings($from+1, $to);
    }
}

@mixin clearfix() {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin mediaMax( $max ) {
	@media screen and ( max-width: #{$max}px ) {
		@content;
	}
}

@mixin mediaMin( $min ) {
	@media screen and ( min-width: #{$min}px ) {
		@content;
	}
}

@mixin media( $min, $max ) {
	@media screen and ( min-width: #{$min}px ) and ( max-width: #{$max}px ) {
		@content;
	}
}