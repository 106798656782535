@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.introduce-section {

  min-height: 100vh;
  position: relative;
  /** webpackIgnore **/
  background-image: url(/images/bg-dots.svg);
  background-position: center;
  @include mediaMin(1199) {
    overflow: hidden;
    &:before {
      content: " ";
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 1920px;
      height: 100%;
      transform: translate(-50%);
      /** webpackIgnore **/
      background-position: right center;
      background-repeat: no-repeat;
      z-index: -1;
    }
  }
  .container {
    position: relative;
  }
  .images-background{
    position: absolute;
    width: 664px;
    max-width: 100%;
    height: 685px;
    // top: 20%;
    // right: 15px;
    top: 17%;
    right: 0;
    z-index: -1;
    @include mediaMax(1199) {
    	overflow: hidden;
    }
    @include mediaMax(991) {
			right: unset;
			left: 50%;
			transform: translate(-50%,0);
    }
    .layer-0{
      z-index: -1;
      position: relative;
      max-width: 100%;
      animation: rotating 360s linear infinite;
    }
    .layer-1{
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      // width: 250px;
      transform: translate(-50%, -50%);
      @include mediaMax(991) {
      	display: none;
      }
    }
  }
  &__inner {
    padding: 282px 15px 85px;
    @include mediaMax(1199) {
      /** webpackIgnore **/
      background-position: right center;
      background-repeat: no-repeat;
      @include mediaMax(740) {
        /** webpackIgnore **/
        background-position: center;
      }
      @include mediaMax(440) {
        background-position: center 28%;
        background-size: 175%;
      }
    }
    @include mediaMax(767) {
      padding: 282px 15px 50px;
    }
    .entry-title {
      position: relative;
      padding-left: 50px;
      @include media(768,991) {
        max-width: 545px;
      }
      @include mediaMax(767) {
        padding-left: 37px;
      }
      @include mediaMax(688) {
        width: 456px;
        max-width: 100%;
      }
      @include mediaMax(422) {
        padding-left: 17px;
      }
      &:before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 2px;
        /** webpackIgnore **/
        background-image: url(/images/title-horizontal-line.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
      h1 {
        font-size: 52px;
        line-height: 66px;
        letter-spacing: 0.25em;
        font-weight: 400;
        @include mediaMax(991) {
          line-height: 56px;
        }
        @include media(992, 1399) {
          font-size: 44px;
        }
        @include media(828,991) {
          font-size: 37px;
        }
        @include mediaMax(827) {
          font-size: 31px;
        }
        @include mediaMax(422) {
          font-size: 30px;
          line-height: 46px;
        }
      }
    }
    .section-content {
      margin-top: 50px;
      @include mediaMax(767) {
        width: 52%;
      }
      @include mediaMax(688) {
        width: 320px;
      }
      p {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.02em;
        font-weight: 400;
        @include mediaMax(991) {
          font-size: 18px;
        }
      }
      a.btn {
        margin-top: 48px;
      }
    }
    .section-footer {
      margin-top: 134px;
      p {
        margin-bottom: 0;
        img {
          position: relative;
          top: -10px;
          margin-left: 5px;
          width: 127px;
        }
      }
    }
  }
}