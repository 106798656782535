.textfield {
  width: 100%;
  .textfield-label{
    margin-bottom: 3px;
    font-family: "EB Garamond",serif;
    font-size: 16px;
    color: #e0e0e0;
    font-weight: 400;
  }

  .textfield-input {
    width: 100%;
    margin: 4px 0 0 0;
    padding: 7px 12px;
    font-family: "EB Garamond",serif;
    border-radius: 5px;
    line-height: 1.5rem;
    outline: none;
    border: none;
    font-size: 100%;
    background-color: #bbc7ce;
  }

  .textfield-error{
    font-size: 18px!important;
    text-transform: lowercase;
    color: crimson;
  }
}