body {
	background-color: #09090A;
	font-family: 'EB Garamond', serif;
	color: #fff;
	font-weight: 400;
}
p {
	font-family: 'EB Garamond', serif;
}
a {
	font-family: 'Oswald', sans-serif;
	color: #fff;
	transition: 0.3s;
	&:hover,
	&:active,
	&:focus {
		color: #e6007a;
	}
}
h1,h2,h3,h4,h5,h6 {
	font-family: 'Oswald', sans-serif;
}
.container {
	@include mediaMax(1199) {
		max-width: 100%;
	}
}
.btn {
	padding: 6px 20px;
	border-radius: 0;
	line-height: 24px;
	transition: 0.3s;
	&:hover,
	&:active,
	&:focus {
		box-shadow: none;
		outline: 0;
	}
}
.btn-grey {
	background-color: #BBC7CE;
	border: 0;
	&:hover {
		background-color: #e6007a;
		color: #fff;
	}
	&:active,
	&:focus {
		outline: 0;
		box-shadow: none;
	}
}
.btn-purple {
	padding: 11px 32px;
	color: #fff;
	font-size: 18px;
	border: 3px solid #E6007A;
	line-height: 27px;
	letter-spacing: 0.1em;
	&:hover {
		background-color: #fff;
		color: #16161D;
		border-color: #fff;
	}
	&:active,
	&:focus {
		outline: 0;
		box-shadow: none;
	}
}

.carousel-controls {
	.carousel-arrows {
		.slick-arrow {
			font-size: 0;
			line-height: 0;
			display: inline-block;
			height: 32px;
			width: 32px;
			padding: 0;
			cursor: pointer;
			color: transparent;
			outline: none;
			background: transparent;
			border: 2px solid #E6007A;
			transition: 0.3s;
			&:before {
				content: " ";
				display: inline-block;
				padding: 9px 0 0;
				font-family: "Font Awesome 5 Free"; 
				color: #fff;
				font-size: 18px;
				font-style: normal;
				font-weight: 700;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
			}
			&:hover {
				opacity: 0.5;
			}
			&.slick-next {
				&:before {
					content: "\f054";
				}
			}
			&.slick-prev {
				margin-right: 16px;
				&:before {
					content: "\f053";
				}
			}
		}
	}
	.carousel-dots {
		.slick-dots {
			margin-top: 62px;
			list-style: none;
			padding-left: 0;
			li {
				display: inline-block;
				margin-right: 16px;
				&:last-child {
					margin-right: 0;
				}
				button {
					font-size: 0;
					line-height: 0;
					display: inline-block;
					height: 8px;
					width: 8px;
					padding: 0;
					cursor: pointer;
					color: transparent;
					outline: none;
					background: transparent;
					border: 1px solid #BBC7CE;
					border-radius: 50%;
					transition: 0.3s;
				}
				&.slick-active {
					button {
						border-color: #fff;
						background-color: #fff;
					}
				} 
			}
		}
	}
}

.slick-slide {
	&:hover,
	&:active,
	&:focus {
		border: 0;
		outline: 0;
		box-shadow: none;
	}
}