.rethinking-ntfs-section {
  padding: 150px 15px;
  @include mediaMax(991) {
    padding: 55px 15px;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    min-height: 715px;
    /** webpackIgnore **/
    background-image: url(/images/section-2.png);
    background-position: left center;
    background-repeat: no-repeat;
    .inner-wrap {
      padding-left: 48px;
      @include mediaMax(1139) {
        padding: 15px 0;
        background-color: #16161d;
        border: 1px dashed #E6007A;
      }
      @include mediaMax(610) {
        width: 100%;
      }
    }
    .entry-title {
      h2 {
        display: inline-block;
        padding: 11px 19px;
        background-color: #16161D;
        font-size: 32px;
        font-weight: 400;
        line-height: 48px;
        letter-spacing: 0.22em;
      }
      @include media(768,1139) {
        // position: absolute;
        // max-width: 318px;
        // right: 40px;
      }
    }
    .section-content {
      margin-left: 115px;
      @include media(1140, 1399) {
        margin-left: 190px;
      }
      @include mediaMax(1139) {
        margin-left: 0;
        padding: 0 19px;
        width: 100%;
        // margin-left: 0;
        // position: absolute;
        // max-width: 318px;
        // right: 15px;
      }
      p {
        margin-bottom: 44px;
        font-size: 21px;
        color: #BBC7CE;
        line-height: 28px;
        letter-spacing: 0.02em;
        @include mediaMax(1139) {
          margin-bottom: 20px;
        }
        @include mediaMax(991) {
          font-size: 18px;
        }
      }
    }
  }
}