.investor-partners-section {
  position: relative;
  padding: 140px 15px 124px;
  @include mediaMax(767) {
    padding: 50px 0 30px;
  }

  .investors {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    gap: 9%;

    img {
      height: 2.5rem;
      max-width: 18%;
      display: inline-block;
      margin-bottom: 2.5rem;
    }
  }

  .bg-left {
    display: block;
    position: absolute;
    height: 100%;
    background-color: #16161D;
    top: 50%;
    left: 0;
    transform: translate(0,-50%);
    z-index: -1;
    @include mediaMax(767) {
      display: none;
    }
  }
  .bg-right {
    display: block;
    position: absolute;
    height: 100%;
    background-color: #BBC7CE;
    top: 50%;
    right: 0;
    transform: translate(0,-50%);
    z-index: -1;
    @include mediaMax(767) {
      display: none;
    }
  }
  .cols {
    @include mediaMax(767) {
      flex-direction: column !important;
      padding: 0;
    }
  }
  .partners {
    position: relative;
    right: 60px;
    @include mediaMax(1199) {
      right: auto;
    }
    @include mediaMax(767) {
      width: 100%;
      padding-bottom: 55px;
      background-color: #16161D;
    }
    .hide-on-desktop{
      @include mediaMin(768) {
        display: none;
      }
    }
    .investor-logo{
      display: flex;
      justify-content: center;
    }
    .entry-title {
      margin-bottom: 80px;
      text-align: center;
      @include mediaMax(767) {
        margin-bottom: 45px;
      }
      h3 {
        font-size: 18px;
        color: #BBC7CE;
        font-weight: 400;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.25em;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
    .partners-list {
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
        max-width: 590px;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        li {
          flex: 0 0 50%;
          width: 50%;
          margin-bottom: 124px;
          text-align: center;
          @include mediaMax(767) {
            margin-bottom: 55px;
          }
          @include mediaMax(480) {
            flex: 0 0 100%;
            width: 100%;
          }
          &:last-child {
            flex: 0 0 100%;
            width: 100%;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .partners-slide {
    position: relative;
    padding: 77px 0 60px 108px;
    color: #16161D;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include mediaMax(767) {
      display: none;
    }
    @include mediaMax(1199) {
      padding-left: 50px;
    }
    @include mediaMax(767) {
      width: 100%;
      background-color: #BBC7CE;
      padding-left: 15px;
      padding-right: 15px;
    }
    .entry-title {
      position: relative;
      max-width: 320px;
      margin-bottom: 84px;
      padding-left: 50px;
      @include mediaMax(991) {
        padding-left: 30px;
      }
      @include mediaMax(767) {
        margin-top: 45px;
      }
      &:before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 2px;
        /** webpackIgnore **/
        background-image: url(/images/section-7-title.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
      h2 {
        font-size: 36px;
        color: #16161D;
        font-weight: 400;
        line-height: 48px;
        letter-spacing: 0.22em;
        @include mediaMax(991) {
          font-size: 34px;
        }
      }
    }
    .partner-item {
      .descr {
        margin-bottom: 80px;
        p {
          font-size: 18px;
          font-weight: 400;
          color: #16161D;
          line-height: 24px;
          letter-spacing: 0.02em;
        }
      }
      .author {
        p {
          margin-bottom: 0;
          font-size: 16px;
          color: #76868E;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.02em;
          &.company-name {
            margin-top: 8px;
            font-family: 'Oswald', sans-serif;
            font-size: 21px;
            color: #16161D;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0.22em;

          }
        }
      }
    }
    .carousel-controls {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-end;
      @include mediaMin(768) {
        position: absolute;
        left: 0;
        bottom: 0;
        padding-left: 108px;
      }
      @include media(768,1199) {
        padding-left: 50px;
      }
      @include mediaMax(767) {
        margin-top: 45px;
        margin-bottom: 40px;
      }
      .carousel-arrows {
        text-align: right;
        .slick-arrow {
          &:before {
            color: #16161D;
          }
        }
      }
      .carousel-dots {
        .slick-dots {
          margin-top: 0;
          margin-bottom: 0;
          li {
            button {
              border: 1px solid #76868E;
            }
            &.slick-active {
              button {
                border-color: #16161D;
                background-color: #16161D;
              }
            }
          }
        }
      }
    }
  }
}