.services-section {
  overflow: hidden;
  padding: 170px 15px 0;
  background-color: #fff;
  color: #16161d;
  @include mediaMax(767) {
    padding: 50px 15px 0;
  }
  &__inner {
    .entry-title {
      position: relative;
      margin-bottom: 58px;
      padding-left: 50px;
      z-index: 2;
      @include mediaMax(991) {
        padding-left: 30px;
      }
      &:before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 2px;
        /** webpackIgnore **/
        background-image: url(/images/section-8-title.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
      h2 {
        font-size: 32px;
        font-weight: 400;
        line-height: 48px;
        letter-spacing: 0.22em;
        @include mediaMax(991) {
          font-size: 28px;
        }
      }
    }
    .slick-list {
      @include mediaMin(768) {
        overflow: visible;
      }
    }
    .services-list {
      .service-item {
        &:hover,
        &:active,
        &:focus {
          outline: 0;
        }
        .cols {
          flex-direction: column-reverse;
          .column {
            &:last-child {
              text-align: center;
            }
          }
        }
        .service-image {
          @include mediaMin(768) {
            margin-top: -82px;
          }
          img {
            display: inline-block;
            max-width: 100%;
          }
        }
        .descr-wrap {
          max-width: 255px;
          @include mediaMax(575) {
            margin-top: 33px;
          }
        }
        &[data-hash="nft-wrap"] {
          .descr-wrap {
            max-width: 463px;
          }
        }
        .item-title {
          margin-bottom: 14px;
          p {
            margin-bottom: 0;
            font-family: 'Oswald', sans-serif;
            font-size: 27px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0.22em;
          }
        }
        .description {
          p {
            margin-bottom: 10px;
            font-size: 18px;
            color: #16161D;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.02em;
          }
        }
      }
    }
    .carousel-controls {
      position: relative;
      @include mediaMax(767) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .carousel-arrows {
        text-align: right;
        .slick-arrow {
          &:before {
            color: #16161D;
          }
        }
      }
      .carousel-dots {
        @include mediaMin(768) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .slick-dots {
          margin-top: 0;
          li {
            &.slick-active {
              button {
                border-color: #16161D;
                background-color: #16161D;
              }
            }
          }
        }
      }
      .carousel-arrows {
        // position: absolute;
        // right: 0;
      }
    }
    .carousel-controls_wrap {
      margin-top: 70px;
      @include media(768,876) {
        margin-bottom: 70px;
      }
      @include mediaMax(767) {
        margin-bottom: 45px;
      }
      .carousel-labels {
        @include mediaMax(876) {
          display: none;
        }
        ul {
          margin-top: 45px;
          padding-left: 0;
          padding-top: 44px;
          padding-bottom: 44px;
          list-style: none;
          border-top: 1px dashed #76868E;
          li {
            position: relative;
            display: inline-block;
            margin-right: 40px;
            &:last-child {
              margin-right: 0;
            }
            a {
              font-size: 14px;
              color: #76868E;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.22em;
              text-decoration: none;
              &:hover,
              &.active {
                color: #16161D;
                &:before {
                  content: " ";
                  position: absolute;
                  top: -45px;
                  left: 0;
                  width: 100%;
                  height: 1px;
                  background-color: #E6007A;
                }
              }
            }
          }
        }
      }
    }
  }
}