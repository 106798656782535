.choicelist {
  width: 100%;

  .choicelist-label {
    margin-bottom: 3px;
    font-family: "EB Garamond", serif;
    font-size: 16px;
    color: #e0e0e0;
    font-weight: 400;
  }

  .choicelist-inputs {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    background-color: #bbc7ce;
    border-radius: 5px;
    padding: 1rem;
  }

  .m-checkbox {
    font-family: "EB Garamond", serif;
    line-height: 1rem;
    position: relative;
    color: #000;
    margin-bottom: 1rem;
    &:last-child{
      margin-bottom: 0;
    }

    input{
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      z-index: -1;
      position: absolute;
      left: -10px;
      top: -8px;
      display: block;
      margin: 0;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background-color: rgba(var(--pure-material-onsurface-rgb,0,0,0),.6);
      box-shadow: none;
      outline: none;
      opacity: 0;
      transform: scale(1);
      pointer-events: none;
      transition: opacity .3s,transform .2s;
      &:checked+span{
          &:after{
            border-color: #000
          }
      }
    }


    & > span {
      display: inline-block;
      width: 100%;
      cursor: pointer;

      &:before {
        content: "";
        display: inline-block;
        box-sizing: border-box;
        margin-right: 8px;
        border: 1px solid;
        border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), .6);
        border-radius: 2px;
        width: 18px;
        height: 18px;
        vertical-align: top;
        transition: border-color .2s, background-color .2s
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 1px;
        left: 1px;
        width: 10px;
        height: 5px;
        border: 2px solid transparent;
        border-right: none;
        border-top: none;
        transform: translate(3px, 4px) rotate(-45deg);
      }
    }
  }

.choicelist-input {
  width: 100%;
  margin: 4px 0 0 0;
  padding: 7px 12px;
  font-family: "EB Garamond", serif;
  border-radius: 5px;
  line-height: 1.5rem;
  outline: none;
  border: none;
  font-size: 100%;
  background-color: #bbc7ce;
}

.choicelist-error {
  font-size: 18px !important;
  text-transform: lowercase;
  color: crimson;
}

}