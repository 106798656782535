.site-footer {
	.link--navigation {
		display: block;
		width: 100%;
		font-size: 16px;
		font-weight: 400;
		text-decoration: none;
		line-height: 24px;
		letter-spacing: 0.1em;
		transition: 0.3s;
		text-decoration: none;
		&:hover,
		&:active,
		&:focus {
			color: #e6007a;
		}
	}

	padding: 94px 15px;
	@include mediaMax(991) {
		padding: 75px 15px;
	}
	.flex-row {
		@include mediaMax(991) {
			flex-direction: column !important;
		}
	}
	.logotype {
		@include media(992, 1199) {
			width: 20%;
		}
		@include mediaMax(991) {
			width: 100%;
			text-align: center;
		}
		img {
			width: 105px;
		}
	}
	.footer-widget-1 {
		border-left: 1px dashed #76868E;
		border-right: 1px dashed #76868E;
		@include media(992, 1199) {
			width: 30%;
		}
		@include mediaMax(991) {
			width: 100%;
			margin: 40px 0 28px;
			padding: 20px 0;
			border-top: 1px dashed #76868E;
			border-bottom: 1px dashed #76868E;
			border-left: 0;
			border-right: 0;
			text-align: center;
		}
		ul {
			padding: 0 15px 0 64px;
			list-style: none;
			@include mediaMax(991) {
				margin-bottom: 0;
				padding: 0 15px;
			}
			li {
				margin-bottom: 16px;
				@include mediaMax(991) {
					margin-bottom: 12px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				a {
					@extend .link--navigation
				}
			}
		}
	}
	.footer-widget-2 {
		padding-left: 64px;
		@include mediaMax(991) {
			width: 100%;
			text-align: center;
			padding-left: 0;
		}
		p {
			font-family: 'Oswald',sans-serif;
			color: #fff;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 0.1em;
			.link {
				display: block;
				font-size: 14px;
				color: #76868E;
				line-height: 22px;
				font-weight: 400;
				letter-spacing: 0.02em;
				text-decoration: none;
				&:hover,
				&:active,
				&:focus {
					color: #e6007a;
				}
			}
			&.title {
				color: #fff;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: 0.1em;
			}
		}
		.cols {
			p {
				&:not(:last-child) {
					margin-right: 72px;
					@include mediaMax(991) {
						margin-top: 10px;
						margin-right: 0;
					}
				}
			}
		}
		.disclaimer {
			font-family: 'EB Garamond', serif;
			font-size: 14px;
			color: #76868E;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 0.02em;
		}
	}
	.widget-row {
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
}