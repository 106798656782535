.select {
  width: 100%;
  .select-label{
    font-family: "EB Garamond",serif;
    font-size: 16px;
    color: #e0e0e0;
    font-weight: 400;
  }

  .select-input-wrap{
    position:relative;

    img {
      width: 16px;
      float: right;
      margin: 12px 16px 0 0;
    }
    .select-input {
      width: 100%;
      cursor: pointer;
      margin: 4px 0 0 0;
      padding: 7px 12px;
      font-family: "EB Garamond",serif;
      border-radius: 5px;
      line-height: 1.5rem;
      outline: none;
      border: none;
      font-size: 100%;
      background-color: #bbc7ce;
      color: #000;
      &.select-open {
        border-radius: 5px 5px 0 0;
      }
    }
    .select-list{
      border-radius: 0 0 5px 5px;
      position: absolute;
      top:48px;
      left: 0;
      background-color: #bbc7ce;
      width: 100%;
      z-index: 50;
      color: #000;

      &:last-child {

      }

      .select-option{
        padding: 12px;
        color: #000;
        font-family: "EB Garamond",serif;
        &:last-child{
          border-radius: 0 0 5px 5px;

        }
        &:hover{
          cursor: pointer;
          background-color:#a4abb0 ;
        }
      }
    }
  }

}