.team-section {
  padding: 170px 15px 118px;
  @include mediaMax(767) {
    padding: 35px 15px 65px;
  }
  &__inner {
    .entry-title {
      position: relative;
      margin-bottom: 58px;
      padding-left: 50px;
      @include mediaMax(991) {
        padding-left: 30px;
      }
      &:before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 2px;
        /** webpackIgnore **/
        background-image: url(/images/section-8-title.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
      h2 {
        font-size: 32px;
        font-weight: 400;
        line-height: 48px;
        letter-spacing: 0.22em;
        @include mediaMax(991) {
          font-size: 28px;
        }
      }
    }
    .cols {
      @include mediaMax(991) {
        order: 1;
      }
      @include mediaMax(519) {
        flex-direction: column !important;
      }
      .column {
        display: flex;
        &:first-child {
          align-items: flex-end;
          @include mediaMax(991) {
            align-items: flex-start;
          }
        }
        &:last-child {
          justify-content: flex-end;
        }
        @include mediaMax(991) {
          margin-top: 18px;
          justify-content: center;
        }
        @include mediaMax(519) {
          flex: 0 0 100%;
          width: 100%;
        }
        .descr {
          max-width: 460px;
          @include mediaMax(1070) {
            max-width: 430px;
          }
          @include mediaMax(767) {
            padding-right: 15px;
          }
          p {
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.02em;
            color: #BBC7CE;
          }
        }
      }
    }
    .team-member {
      position: relative;
      &:hover,
      &:active,
      &:focus {
        border: 0;
        outline: 0;
      }
      @include mediaMax(991) {
        display: flex;
        flex-direction: column;
      }
    }
    .member-photo {
      @include mediaMax(991) {
        order: 2;
      }
      @include mediaMax(519) {
        // padding-left: 5px;
        // padding-right: 5px;
      }
      img {
        @include mediaMax(1024) {
          max-width: 100%;
        }
      }
    }
    .member-name {
      @include mediaMin(992) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      @include mediaMax(991) {
        order: 0;
        text-align: center;
      }
      h3 {
        font-size: 56px;
        color: #fff;
        font-weight: 400;
        line-height: 58px;
        letter-spacing: 0.22em;
        @include mediaMin(515) {
          white-space: nowrap;
        }
        @include mediaMax(1024) {
          font-size: 42px;
        }
        @include mediaMax(991) {
          font-size: 36px;
        }
      }
    }
    .carousel-controls_wrap {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 30px;
      .carousel-controls {
        display: flex;
        justify-content: space-between;
        flex: 0 0 504px;
        width: 504px;
        @include mediaMax(991) {
          flex: 0 0 100%;
          width: 100%;
        }
        .slick-dots {
          margin-top: 0;
        }
      }
    }
  }
}