.pieces-sections {
  position: relative;
  .bg-left {
    display: block;
    position: absolute;
    height: 100%;
    background-color: #16161D;
    top: 50%;
    left: 0;
    transform: translate(0,-50%);
    background-color: #16161D;
    z-index: -1;
    @include mediaMax(767) {
      width: 100%;
    }
  }
  .bg-right {
    display: block;
    position: absolute;
    height: 100%;
    background-color: #27282F;
    top: 50%;
    right: 0;
    transform: translate(0,-50%);
    z-index: -1;
    @include mediaMax(767) {
      width: 100%;
    }
  }
  .cols {
    @include mediaMax(767) {
      padding: 0;
      flex-direction: column !important;
    }
    .column {
      padding-top: 172px;
      position: relative;
      overflow: hidden;
      @include mediaMax(767) {
        flex: 0 0 auto;
        width: 100%;
        padding-top: 22px;
      }
      &:first-child {
        background-color: #16161D;
        @include mediaMin(768) {
          border-right: 1px dashed #E6007A;
        }
        @include mediaMax(767) {
          padding: 48px 15px 0;
        }
        .entry-title {
          position: relative;
          padding-left: 50px;
          max-width: 245px;
          @include mediaMax(991) {
            padding-left: 30px;
          }
          &:before {
            content: " ";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 2px;
            /** webpackIgnore **/
            background-image: url(/images/title-horizontal-line-small.svg);
            background-repeat: no-repeat;
            background-position: center;
          }
          h2 {
            font-size: 32px;
            font-weight: 400;
            line-height: 48px;
            letter-spacing: 0.22em;
            @include mediaMax(991) {
              font-size: 28px;
            }
          }
        }
      }
      &:last-child {
        padding-left: 80px;
        padding-bottom: 230px;
        @include mediaMax(767) {
          padding: 0 15px;
        }
      }
      .slider-navigation {
        margin-top: 163px;
        @include mediaMax(767) {
          margin-top: 35px;
        }
        ul {
          list-style: none;
          padding-left: 0;
          @include mediaMax(440){
            display: flex;
            justify-content: space-between;
          }
          li {
            @include mediaMax(767) {
              display: inline-block;
              margin-right: 30px;
              &:last-child {
                margin-right: 0;
              }
            }
            @include mediaMax(489) {
              margin-right: 15px;
            }
            a {
              display: block;
              width: 100%;
              padding: 5px 0;
              font-size: 14px;
              color: #76868E;
              line-height: 24px;
              letter-spacing: 0.22em;
              text-decoration: none;
              &:hover,
              &.active {
                color: #BBC7CE;
              }
              span:first-child{
                padding-right: 5px;
              }
              @include mediaMax(440){
                display: flex;
                flex-direction: column;
                span:first-child{
                  padding-right: 0;
                }
              }

              @include mediaMin(768) {
                &:hover,
                &.active {
                  border-right: 3px solid #fff;
                }
              }
              @include mediaMax(1024) {
                text-align: center;
              }
            }
          }
        }
      }
      .section-title {
        @include mediaMax(767) {
          margin-top: 45px;
        }
        p {
          display: flex;
          flex-direction: column;
          margin-bottom: 0;
          font-size: 27px;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0.02em;
          @include media(767, 830){
            font-size: 24px;
          }
          @include mediaMax(555) {
            font-size: 25px;
          }
          @include mediaMax(519) {
            font-size: 18px;
          }
          @include mediaMax(390) {
            font-size: 16px;
          }
          @include mediaMax(353) {
            font-size: 14px;
          }
        }
      }
      .section-carousel {
        padding-top: 98px;
        padding-bottom: 50px;
        @include mediaMax(767) {
          padding-top: 50px;
          padding-bottom: 5px;
        }
        .slide-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          &:active,
          &:focus {
            border: 0;
            outline: 0;
          }
          @include mediaMax(1024) {
            flex-direction: column-reverse;
          }
          .item-descr {
            max-width: 255px;
            @include mediaMax(1024) {
              margin-top: 30px;
              max-width: 100%;
              text-align: center;
            }
          }
          .item-image {
            img {
              @include mediaMax(767) {
                max-width: 100%;
              }
            }
          }
          .item-title {
            margin-bottom: 14px;
            p {
              margin-bottom: 0;
              font-family: 'Oswald', sans-serif;
              font-size: 27px;
              font-weight: 400;
              line-height: 32px;
              letter-spacing: 0.22em;
            }
          }
          .item-content {
            p {
              font-size: 18px;
              line-height: 24px;
              letter-spacing: 0.02em;
            }
          }
        }
      }
    }
  }
  .bg-line-right {
    position: absolute;
    z-index: -1;
    width: 1575px;
    height: 100%;
    background-color: #27282F;
    top: 50%;
    left: 0;
    -ms-transform: translate(0,-50%);
    transform: translate(0,-50%);
  }
  .carousel-controls {
    @include mediaMax(767) {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-top: 25px;
      margin-bottom: 47px;
    }
    .slick-dots {
      @include mediaMax(767) {
        margin-top: 0;
      }
    }
  }
}