.contact-section {
  padding: 121px 15px 116px;
  background-color: #fff;
  color: #16161D;
  @include mediaMax(767) {
    padding: 50px 15px 50px;
  }
  &__inner {
    .entry-title {
      text-align: center;
      h2 {
        font-size: 36px;
        color: #16161D;
        font-weight: 400;
        line-height: 48px;
        letter-spacing: 0.1em;
      }
    }
    .section-content {
      margin-top: 55px;
      text-align: center;
      ul {
        margin-top: 16px;
        margin-bottom: 55px;
        list-style: none;
        padding-left: 0;
        li {
          display: inline-block;
          margin: 0 19px;
          a {
            font-size: 32px;
            color: #16161D;
            line-height: 48px;
            transition: 0.3s;
            &:hover,
            &:active,
            &:focus {
              color: #e6007a;
            }
          }
        }
      }
      p {
        font-size: 27px;
        line-height: 32px;
        text-align: center;
        color: #16161D;
        &.lets-chat {
          font-size: 16px;
          letter-spacing: 0.02em;
          color: #16161D;
          line-height: 24px;
          a {
            display: inline-block;
            margin-left: 12px;
            padding: 3px 12px;
            color: #E6007A;
            border: 1.5px solid #E6007A;
            letter-spacing: 0.1em;
            text-decoration: none;
            transition: 0.3s;
            &:hover,
            &:active,
            &:focus {
              background-color: #e6007a;
              color: #fff;
            }
          }
        }
      }
    }
  }
}