#thank-modal{
  padding: 25px;
  .section-content{
    padding-bottom: 2rem;
    border-bottom: 1px solid #fff;
  }
  .thank-social {
    margin-top: 1rem;
    text-align: center;
    ul{
      margin-top: 16px;
      margin-bottom: 55px;
      list-style: none;
      padding-left: 0;
      li{
        display: inline-block;
        margin: 0 19px;
        a{
          font-size: 32px;
          line-height: 48px;
          transition: 0.3s;
          &:active, &:hover{
            color: #e6007a;
          }
        }
      }
    }
  }
}