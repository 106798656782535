#contact-modal {
  .modal-body {
    font-family: "Oswald", sans-serif;
    padding: 1rem 2rem;

    > div {
      margin-bottom: 1rem;
    }

  }

  .modal-dialog {
    max-width: 600px;
  }

  .modal-content {
    background-color: #16161D;
    max-width: 600px;
    width: 100%;
  }

  .btn-submit {
    width: 120px;
    background-color: #E6007A;
    color: white;
    font-size: 20px;
    border-radius: 23px;
  }

  .btn-disabled{
    background-color: #c1bcbf;
    pointer-events: none;
  }

  .recaptcha {
    color: #bebebe;
    margin-top: 1rem;
    font-size: 12px;

    span {
      padding: 0 5px;
    }
  }
  .grecaptcha-badge {
    visibility: hidden !important;
    opacity: 0 !important;
  }
}

.modal-open { 
  .modal {
    background-color: rgba(22,22,29,0.6);
  }
}