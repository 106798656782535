.gen-shards-section {
  padding: 150px 15px 260px;
  @include mediaMax(767) {
    padding: 50px 15px 80px;
  }
  &__inner {
    .entry-title {
      margin-bottom: 94px;
      text-align: center;
      h2 {
        font-size: 36px;
        line-height: 48px;
        letter-spacing: 0.22em;
        @include mediaMax(991) {
          font-size: 28px;
        }
      }
    }
    img {
      max-width: 100%;
    }
    .section-content {
      text-align: center;
    }
  }
}