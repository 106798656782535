.compare-tokens-sections {
  position: relative;
  @include mediaMax(519) {
    overflow: hidden;
  }

  .cols {
    @include mediaMax(991) {
      padding-left: 0;
      padding-right: 0;
      flex-direction: column !important;
    }

    .entry-title {
      @include mediaMax(991) {
        text-align: center;
      }

      h3 {
        font-size: 32px;
        color: #fff;
        font-weight: 400;
        line-height: 48px;
        letter-spacing: 0.22em;
      }
    }

    .section-content {
      position: relative;

      .image-block p.title,
      > p {
        &.title {
          font-family: 'Oswald', sans-serif;
          font-size: 42px;
          color: #fff;
          font-weight: 400;
          line-height: 48px;
          letter-spacing: 0.05em;
          white-space: nowrap;
          @include mediaMin(992) {
            position: absolute;
          }
          @include media(991, 1199) {
            font-size: 38px;
          }
        }
      }
    }

    .column-before {
      padding-top: 132px;
      padding-bottom: 193px;
      @include mediaMax(1110) {
        width: 38%;
      }
      @include mediaMax(991) {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 32px;
        background-color: #BBC7CE;
      }

      .bg-line-left {
        position: absolute;
        z-index: -1;
        width: 1075px;
        height: 100%;
        background-color: #BBC7CE;
        top: 50%;
        left: 0;
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        @include mediaMax(1199) {
          width: 92%;
        }
        @include mediaMax(991) {
          display: none;
        }
      }

      .entry-title {
        h3 {
          color: #16161D;
        }

        @include mediaMax(991) {
          text-align: center;
        }
      }

      .section-content {
        @include mediaMin(992) {
          min-height: 618px;
        }
        @include mediaMax(991) {
          text-align: center;
        }

        img {
          @include mediaMin(992) {
            position: absolute;
            top: calc(50% + 35px);
            left: 50%;
            transform: translate(-50%, -50%);
          }
          @include mediaMax(991) {
            position: relative;
            left: 52px;
          }
          @include mediaMax(450) {
            position: relative;
            left: calc(50% - 158px);
          }
        }

        ul {
          @include mediaMin(992) {
            position: absolute;
            top: 0;
            right: 32px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          @include media(992, 1399) {
            right: 5px;
          }
          @include media(992, 1110) {
            right: 32px;
          }
          @include mediaMax(991) {
            position: relative;
            top: -40px;
            display: flex;
            justify-content: space-between;
            margin: 0 20px;
          }

          &:before {
            position: absolute;
            content: " ";
            z-index: -1;
            @include mediaMin(992) {
              top: 0;
              left: -30px;
              width: 1px;
              height: 100%;
              border-left: 1px dashed #16161D;
            }
            @include mediaMax(991) {
              width: 100%;
              left: 0;
              top: -18px;
              border-top: 1px dashed #16161D;
              z-index: 1;
            }
          }
        }

        p {
          &.title {
            color: #16161D;
            @include mediaMin(992) {
              top: calc(50% - 11px);
              left: calc(50% + 25px);
              width: 100%;
              transform: translate(-50%, -50%);
            }
            @include mediaMax(991) {
              position: absolute;
              top: calc(50% - 58px);
              left: calc(50% - 18px);
              width: 100%;
              transform: translate(-50%, -50%);
            }
            @include mediaMax(767) {
              left: calc(50% - 12px);
            }
          }
        }
      }

      ul {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        li {
          p {
            margin-bottom: 0;
            font-family: 'Oswald', sans-serif;
            font-size: 21px;
            color: #16161D;
            line-height: 28px;
            letter-spacing: 0.22em;
            @include mediaMax(1399) {
              font-size: 16px;
            }
          }
        }
      }
    }

    .column-after {
      padding-top: 132px;
      padding-bottom: 194px;
      padding-left: 44px;
      background-color: #16161D;
      @include mediaMax(1110) {
        width: 62%;
      }
      @include mediaMax(991) {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 32px;
        padding-left: 0;
      }

      .section-content {
        display: flex;
        flex-direction: row;
        min-height: 618px;
        @include mediaMax(991) {
          flex-direction: column;
        }
      }

      .image-block {
        position: relative;
        flex: 0 0 382px;
        width: 382px;
        z-index: 3;
        @include media(1111, 1199) {
          flex: 0 0 315px;
          width: 315px;
        }
        @include media(992, 1110) {
          flex: 0 0 250px;
          width: 250px;
        }
        @include mediaMax(991) {
          flex: 0 0 100%;
          width: 100%;
          text-align: center;
        }

        img {
          @include mediaMin(992) {
            position: absolute;
            top: calc(50% + 22px);
            left: 50%;
            transform: translate(-50%, -50%);
          }
          @include mediaMax(991) {
            position: relative;
            left: 23px;
          }
        }

        p {
          &.title {
            @include mediaMin(992) {
              top: calc(50% - 18px);
              left: calc(50% - 28px);
              transform: translate(-50%, -50%);
            }
            @include media(992, 1199) {
              left: calc(50% - 25px);
            }
            @include mediaMax(991) {
              position: absolute;
              left: 50%;
              top: 32%;
              transform: translate(-50%, 0);
            }
          }
        }
      }

      ul {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 0 0 calc(100% - 382px);
        width: calc(100% - 382px);
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        &:before {
          content: " ";
          position: absolute;
          top: 0;
          left: -123px;
          width: 1px;
          height: 100%;
          border-left: 1px dashed #E6007A;
          z-index: 1;
        }

        @include media(1111, 1199) {
          flex: 0 0 calc(100% - 315px);
          width: calc(100% - 315px);
        }
        @include mediaMax(1110) {
          flex: 0 0 calc(100% - 250px);
          width: calc(100% - 250px);
        }
        @include mediaMax(991) {
          flex-direction: row;
          flex: 0 0 100%;
          width: 100%;
          margin-top: -45px;
        }
        @include mediaMax(767) {
          flex-wrap: wrap;
        }

        li {
          display: flex;
          flex-direction: row;
          @include mediaMax(991) {
            flex-direction: column;
            align-items: center;
            text-align: center;
          }
          @include mediaMax(767) {
            margin-bottom: 10px;
            padding: 0 10px;
            flex: 0 0 auto;
            width: 50%;
            &:last-child {
              width: 100%;
              max-width: 299px;
              margin-bottom: 0;
              margin-left: auto;
              margin-right: auto;
            }
          }
          @include mediaMax(519) {
            width: 100%;
            max-width: 299px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 35px;
          }

          .icon {
            margin-right: 25px;
            @include mediaMax(991) {
              margin-right: 0;
              margin-bottom: 12px;
            }
          }

          p {
            margin-bottom: 0;

            &.title {
              margin-bottom: 10px;
              font-family: 'Oswald', sans-serif;
              font-size: 21px;
              line-height: 28px;
              letter-spacing: 0.22em;
              @include mediaMax(1199) {
                font-size: 18px;
              }
            }

            &.descr {
              font-size: 16px;
              color: #BBC7CE;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: 0.02em;
              @include mediaMax(1199) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}