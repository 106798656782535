.site-header {
	position: absolute;
	top:0;
	left: 0;
	width: 100%;
	z-index: 11;
	.topbar {
		// background-color: rgba(118, 134, 142, 0.4);
		background-color: #3D444A;
		text-align: center;
		p {
			margin: 0;
			padding: 12px 10px;
			font-size: 16px;
			color: #fff;
			line-height: 1;
			letter-spacing: 0.02em;
			a {
				margin-left: 8px;
				font-family: "EB Garamond",serif;
				font-size: 16px;
				color: #fff;
				transition: 0.3s;
				text-decoration: none;
				@include mediaMax(413) {
					display: block;
					margin-top: 4px;
					margin-left: 0;
				}
				&:hover,
				&:active,
				&:focus {
					color: #e6007a;
				}
			}
		}
	}
	&__inner {
		position: relative;
		padding: 32px 15px;
		.navigation {
			@include mediaMax(1089) {
				display: none;
			}
			ul {
				display: flex;
				flex-direction: row;
				margin-bottom: 0;
				padding-left: 0;
				list-style: none;
				li {
					margin: 0 18px;
					a {
						display: inline-block;
						padding: 0 6px;
						font-family: 'Oswald', sans-serif;
						font-size: 18px;
						color: #fff;
						text-decoration: none;
						line-height: 1;
						letter-spacing: 0.25em;
						transition: 0.3s;
						&:hover,
						&:active,
						&:focus {
							color: #e6007a;
						}
					}
				}
			}
		}
	}
	.mobile-nav_wrap {
		display: none;
		position: relative;
		@include mediaMax(1089) {
			display: block;
		}
		.mobile-nav {
			display: none;
			position: absolute;
			top: 17px;
			left: 0;
			width: 100%;
			background: #bbc7ce;
			color: #16161d;
			// border: 1px dashed #e6007a;
			ul {
				margin-bottom: 0;
				padding: 9px 0;
				list-style: none;
				li {
					a {
						display: block;
						width: 100%;
						padding: 10px;
						font-size: 21px;
						color: #16161d;
						text-align: center;
						text-decoration: none;
					}
				}
			}
		}
	}
	.mobile-menu {
		display: none;
		transition: none;
		@include mediaMax(1089) {
			display: block;
		}
	}
	.sign-in {
		@include mediaMax(1089) {
			display: none;
		}
	}
}