.contact-page-section {
    padding-top: 170px;
    h5 {
        font-weight: 400; }
    .border-font-content {
        h1 {
            font-weight: 200;
            font-size: 119.792px;
            line-height: 178px;
            color: #D9D9D9;
            margin-bottom: 50px; } }
    .partner-title {
        font-weight: 300;
        font-size: 26.6068px;
        line-height: 39px;
        letter-spacing: 0.6em;
        font-family: 'Oswald';
        margin: 50px 0; }
    .chain-company {
        display: flex;
        align-items: center;
        margin-bottom: 60px; }
    .contact-form {
        background: linear-gradient(300deg, rgba(255, 0, 113, 0.27) 13.26%, rgba(22, 22, 28, 0.76) 83.75%);
        border-radius: 60px;
        padding: 40px 40px !important;
        .schedule-call {
            a {
                color: #FF0071; } }
        .form-check-input {
            background-color: transparent;
            border: 1px solid #ffffff; }
        .input-valid-star {
            color: #FF0071;
            font-weight: 600;
            font-size: 15px; }
        .row {
            margin-bottom: 30px;
            align-items: center; }
        select {
            position: relative;
            border: 1px solid #ffffff;
            border-radius: 43px;
            font-family: 'Oswald';
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            padding: 10px 30px;
            display: flex;
            align-items: center;
            height: 50px;
            width: 100%;
            background: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            background: transparent;
            color: #ffffff;
            background-image: url("/images/down-aroow-icon.svg");
            background-repeat: no-repeat;
            background-position-x: 95%;
            background-position-y: 50%;
            option {
                background: rgba(255, 66, 53, 0.95);
                color: #ffffff;
                padding: 10px 30px; } }
        input[type="date"] {
            position: relative; }
        input[type="date"]::-webkit-calendar-picker-indicator {
            background: url("/images/down-aroow-icon.svg") no-repeat;
            background-position-x: 95%;
            background-position-y: 50%;
            width: 20px;
            height: 20px;
            border-width: thin;
            bottom: 0;
            color: transparent;
            cursor: pointer;
            height: auto;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: auto; }
        .select-label {
            margin-bottom: 20px; }
        label {
            font-weight: 300;
            font-size: 18px;
            line-height: 22px;
            color: #ffffff;
            font-family: 'Oswald'; }
        .contact-fields {
            font-family: 'Oswald';
            border: 1px solid #ffffff;
            border-radius: 43px;
            font-weight: 300;
            font-size: 18px;
            line-height: 22px;
            padding: 10px 30px;
            display: flex;
            align-items: center;
            height: 50px;
            width: 100%;
            background: transparent;
            color: #ffffff; }
        .submit-btn {
            display: flex !important;
            justify-content: center !important;
            button {
                background: #FF0071;
                border: none;
                padding: 12px 65px;
                border-radius: 30px;
                color: #fff;
                font-weight: 600;
                font-size: 26.5574px;
                line-height: 33px;
                font-family: 'Oswald'; } }
        .file-upload {
            input {
                border: 1px solid #ffffff;
                border-radius: 43px;
                height: 40px;
                font-size: 16px;
                padding: 7px 30px;
                color: #ffffff; }
            [type="file"]::-webkit-file-upload-button {
                display: none; } } } }
@media screen and (max-width: 1600px) {
    .contact-page-section {
        h2 {
            font-size: 50.9887px !important;
            line-height: 65px !important; } }
    .contact-page-section {
        .border-font-content {
            h1 {
                font-size: 80.792px;
                line-height: 89px; } } } }

@media screen and (max-width: 1400px) {
    .contact-page-section {
        .contact-form {
            .contact-fields {
                font-size: 15px;
                line-height: 20px;
                padding: 5px 20px;
                height: 40px; }
            label {
                font-size: 15px;
                line-height: 20px; }
            select {
                font-size: 15px;
                line-height: 20px;
                padding: 5px 20px;
                height: 40px; }
            .submit-btn {
                button {
                    background: #FF0071;
                    border: none;
                    padding: 10px 60px;
                    border-radius: 30px;
                    color: #fff;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 26px; } } } }
    .contact-page-section {
        .partner-title {
                font-size: 20px;
                line-height: 28px; } } }


@media screen and (max-width: 1200px) {
    .contact-page-section {
        .contact-form {
            label {
                font-size: 13px;
                line-height: 20px; } } } }

@media screen and (max-width: 992px) {
    .contact-page-section {
        h2 {
            font-size: 37.9887px !important;
            line-height: 56px !important;
            margin-bottom: 20px !important; } }
    .contact-page-section {
        .border-font-content {
            h1 {
                font-size: 60.792px;
                line-height: 70px; } } }
    .contact-page-section {
        .partner-title {
            margin: 30px 0;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 8px; } }
    .contact-page-section {
        .contact-form {
            .row {
                margin-bottom: 20px; } } } }
@media screen and (max-width: 767px) {
    .contact-page-section {
        h2 {
            font-size: 32.774px !important;
            line-height: 41px !important; }
        h5 {
            margin: 30px 0 14px 0 !important;
            font-size: 20px !important;
            line-height: 26px !important; }
        .chain-company {
            display: flex;
            justify-content: center;
            margin-bottom: 30px; }
        .border-font-content-footer {
            padding-top: 16px;
            span {
                font-size: 24.7646px;
                line-height: 31px;
                -webkit-text-stroke-width: 1.16px; } }
        .contact-form {
            padding: 23px 20px !important;
            margin-top: 15px;
            border-radius: 26.388px;
            .form-check-inline {
                a {
                    font-size: 14px; } }
            label {
                font-size: 10px;
                line-height: 12px; }
            .contact-fields {
                font-size: 12px;
                line-height: 16px;
                padding: 5px 20px;
                height: 30px; }
            input[type="date"]::-webkit-calendar-picker-indicator {
                background: url("/images/aroow-small-icon.svg") no-repeat;
                background-position-x: 95%;
                background-position-y: 50%; }
            select {
                font-size: 12px;
                line-height: 16px;
                padding: 5px 20px;
                height: 30px;
                background-image: url("/images/aroow-small-icon.svg"); }
            .file-upload {
                input {
                    font-size: 12px;
                    line-height: 16px;
                    padding: 7px 20px;
                    height: 30px; } }
            .submit-btn {
                button {
                    padding: 8px 30px;
                    font-size: 11.6799px;
                    line-height: 14px; } } } } }

//
.why-moxie-section {
    padding: 100px 0 0 0;
    p {
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        font-family: 'Oswald'; }
    h2 {
        font-weight: 600;
        font-size: 48px;
        font-family: 'Oswald';
        line-height: 71px; }
    .why-moxie-card {
        padding: 30px 40px;
        background: #2B2B2B;
        border-radius: 60px;
        margin-bottom: 30px;
        &.why-moxie-card-bg {
            background: #2B2B2B; }
        .why-moxie-card-p {
            padding-right: 50px; }
        .easy-implementations {
            position: absolute;
            right: -18px;
            width: 130px;
            bottom: -70px; }
        .uiux-icon {
            position: absolute;
            right: -18px;
            width: 130px;
            bottom: -20px; } }
    .cards {
        .why-moxie-card {
            height: 393px;
            align-self: center;
            &.small {
                padding-top: 75px; }
            .font-big {
                font-weight: 600;
                font-size: 80px;
                line-height: 100px;
                display: block;
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-color: #FF0071;
                -webkit-text-stroke-width: 2px;
                font-family: 'Oswald'; } } } }
.contact-page {
    .why-moxie-card {
        background: #2B2B2B;
        &.why-moxie-card-bg {
            background: #2B2B2B; } } }
.section-heading {
    margin-bottom: 40px;
    .why-moxie-heading {
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 300;
        font-size: 50px;
        line-height: 158.36%;
        letter-spacing: 0.44em;
        text-align: center;
        img {
            height: 104px;
            margin-left: 25px; }
        .border-font-content {
            font-family: 'Oswald';
            font-weight: 600;
            font-size: 92.8873px;
            line-height: 123px;
            display: block;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-color: #FF4235;
            -webkit-text-stroke-width: 3px; } } }

@media screen and (max-width: 1600px) {
    .section-heading {
        .why-moxie-heading {
            font-size: 40px;
            img {
                height: 80px; } } }
    .why-moxie-section {
        .why-moxie-card {
            .uiux-icon {
                width: 90px;
                right: 0; }
            .easy-implementations {
                right: 0;
                width: 90px;
                bottom: -30px; } }
        .cards {
            .why-moxie-card {
                img {
                    width: 80px;
                    margin-bottom: 10px; }
                .font-big {
                    font-size: 65px;
                    line-height: 80px; } } } } }

@media screen and (max-width: 1400px) {
    .why-moxie-section {
        .cards {
            .why-moxie-card {
                padding: 20px 30px;
                border-radius: 30px;
                height: 340px; } }
        .why-moxie-card {
            padding: 20px 30px;
            border-radius: 30px; }
        .cards {
            .why-moxie-card {
                img {
                    width: 60px;
                    margin-bottom: 20px; }
                .font-big {
                    font-weight: 600;
                    font-size: 50px;
                    line-height: 60px; } } } } }

@media screen and (max-width: 1200px) {
    .why-moxie-section {
        h2 {
            font-size: 30px !important;
            line-height: 40px !important; }
        .why-moxie-card {
            padding: 20px 30px;
            border-radius: 30px; }
        .cards {
            .why-moxie-card {
                height: auto;
                img {
                    width: 60px;
                    margin-bottom: 20px; }
                .font-big {
                    font-weight: 600;
                    font-size: 50px;
                    line-height: 60px; } } } } }

@media screen and (max-width: 992px) {
    .why-moxie-section {
        .cards {
            .why-moxie-card {
                // display: flex
                // width: 48%
                align-items: center;
                h2 {
                    margin-bottom: 0px; }
                img {
                    margin-bottom: 0px;
                    width: 50px;
 }                    // margin-right: 20px
                .font-big {
                    font-size: 35px;
                    line-height: 40px;
 }                    // margin-left: 20px
                &.small {
                    padding-top: 20px; } } } }
    .section-heading {
        margin-bottom: 25px;
        .why-moxie-heading {
            font-size: 35px;
            line-height: 58px;
            img {
                height: 50px;
                margin-left: 15px; } } } }

@media screen and (max-width: 767px) {
    .why-moxie-section {
        padding: 40px 0 0 0;
        .why-moxie-card {
            padding: 20px;
            .easy-implementations {
                right: 0;
                width: 65px;
                bottom: 0px; }
            .uiux-icon {
                right: 0;
                width: 65px;
                bottom: -30px; } }
        .cards {
            .why-moxie-card {
                display: block;
                width: 100%;
                padding: 10px 20px;

                h2 {
                    margin-bottom: 10px; }
                img {
                    margin-bottom: 10px;
                    width: 70px;
                    margin-right: 0; }
                .font-big {
                    font-size: 60.1718px;
                    line-height: 75px;
                    margin-left: 0; } } } }
    .section-heading {
        margin-bottom: 25px;
        .why-moxie-heading {
            font-size: 25px;
            line-height: 36px;
            img {
                height: 40px;
                margin-left: 15px; } } } }
